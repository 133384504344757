import { useNavigate } from 'react-router-dom';
import { useCommonSearchParams } from '../../../hooks/params/useCommonSearchParams';
import { TemplatesViewFilter, routes } from '../../../static/routes';
import { searchParamsData } from '../../../static/searchParamsData';

export const useTemplatesViewParams = () => {
  const navigate = useNavigate();

  const { searchFilter, debouncedSearchValue, page, tagFilters, cityFilter, ownerFilter, getValueFromSearchParams } =
    useCommonSearchParams();

  const gameCharacterFilter = getValueFromSearchParams(searchParamsData.gameCharacterFilter);

  const navigateWithFilters = (filters: Record<string, string | number | string[]>): void => {
    navigate(routes.templates(filters));
  };

  const handleChange = (filter: TemplatesViewFilter, newVal: string | number): void => {
    const filters: Record<string, string | number | string[]> = {
      ...(!!searchFilter && { search: searchFilter }),
      ...(!!page && { page }),
      ...(!!tagFilters && { tag: tagFilters }),
      ...(!!gameCharacterFilter && { gameCharacter: gameCharacterFilter }),
      ...(!!cityFilter && { city: cityFilter }),
      ...(!!ownerFilter && { owner: ownerFilter }),
      [filter]: newVal,
    };
    navigateWithFilters(filters);
  };

  return {
    searchFilter,
    debouncedSearchValue,
    handleChangeSearchFilter: (newVal: string) => handleChange('search', newVal),

    page,
    handleChangePage: (newVal: number) => handleChange('page', newVal),

    tagFilters,
    handleChangeTagFilters: (newTags: string[]) => handleChange('tag', newTags.join(';')),

    gameCharacterFilter,
    handleChangeGameCharacterFilter: (newVal: string) => handleChange('gameCharacter', newVal),

    cityFilter,
    handleChangeCityFilter: (newVal: string) => handleChange('city', newVal),

    ownerFilter,
    handleChangeOwnerFilter: (newVal: string) => handleChange('owner', newVal),
  };
};
