import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useTemplateQuestionsFilters from '../../components/forms/templateForm/hooks/useTemplateQuestionsFilters';
import TemplateForm from '../../components/forms/templateForm/TemplateForm';
import { TemplateFormOnSubmit } from '../../components/forms/templateForm/types/TemplateFormTypes';
import { prepareTemplateRequestData } from '../../components/forms/templateForm/utils/prepareTemplateRequestData';
import TitleWrapper from '../../components/wrappers/titleWrapper/TitleWrapper';
import useQuestions from '../../hooks/api/questions/useQuestions';
import useCreateTemplate from '../../hooks/api/templates/useCreateTemplates';
import useSendTemplateAds from '../../hooks/api/templates/useSendTemplateAds';
import useTemplateGameCharacters from '../../hooks/api/templates/useTemplateGameCharacters';
import useTemplateGameTypes from '../../hooks/api/templates/useTemplateGameTypes';
import useUserDetails from '../../hooks/api/users/useUserDetails';
import { routes } from '../../static/routes';
import { GameTypes } from '../../types/GlobalTypes';
import { UserTypes } from '../../types/user/userTypes';

const AddTemplateView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUserDetails();

  const { gameTypes } = useTemplateGameTypes();
  const { gameCharacters } = useTemplateGameCharacters();
  const { createTemplate, isLoading } = useCreateTemplate();
  const { sendTemplateAds } = useSendTemplateAds();

  const {
    searchValue,
    setSearchValue,
    debouncedSearchValue,
    questionTypeFilter,
    setQuestionTypeFilter,
    tagFilters,
    setTagFilters,
    cityFilter,
    setCityFilter,
    arcadeFilter,
    setArcadeFilter,
  } = useTemplateQuestionsFilters();

  const { questions } = useQuestions({
    search: debouncedSearchValue,
    filters: {
      tags: tagFilters,
      questionType: questionTypeFilter,
      city: cityFilter,
      isArcade: arcadeFilter,
    },
  });

  const handleSubmitForm = async ({ data, questions, newAds }: TemplateFormOnSubmit) => {
    const requestData = prepareTemplateRequestData({ data, questions });

    const res = await createTemplate(requestData);
    const templateId = res.data.id;

    if (!!templateId && newAds.length !== 0) {
      sendTemplateAds({ templateId, ads: newAds });
    }

    navigate(routes.templates());
  };

  const availableGameTypes =
    user?.role === UserTypes.GLOBAL_ADMIN
      ? gameTypes
      : gameTypes?.filter(
          gameType => gameType.code !== GameTypes.INDIVIDUAL || gameType.code !== GameTypes.INDIVIDUAL_PAID,
        );

  return (
    <TitleWrapper title={t('addTemplate.title')}>
      <TemplateForm
        onSubmitForm={handleSubmitForm}
        isLoadingSubmit={isLoading}
        submitBtnText={t('addTemplate.submitBtn')}
        allQuestions={questions?.data ?? []}
        gameCharactersOptions={gameCharacters ?? []}
        gameTypesOptions={availableGameTypes ?? []}
        questionFiltersProps={{
          searchValue,
          setSearchValue,
          questionType: questionTypeFilter,
          setQuestionType: setQuestionTypeFilter,
          tags: tagFilters,
          setTags: setTagFilters,
          city: cityFilter,
          setCity: setCityFilter,
          isArcade: arcadeFilter,
          setIsArcade: setArcadeFilter,
        }}
      />
    </TitleWrapper>
  );
};

export default AddTemplateView;
