import { colors } from '../../../styles/variables';

interface MapMarkerSnippetCardProps {
  children: React.ReactNode;
}

const MapMarkerSnippetCard = ({ children }: MapMarkerSnippetCardProps) => {
  return (
    <div className="relative bg-white900 rounded-lg pt-3 px-4 pb-6 w-64">
      <div>{children}</div>
      <svg
        className="absolute left-1/2 -translate-x-1/2 -bottom-[6px]"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
      >
        <path
          d="M16 0.299805H0L6.58579 6.88559C7.36684 7.66664 8.63317 7.66664 9.41421 6.88559L16 0.299805Z"
          fill={colors.white900}
        />
      </svg>
    </div>
  );
};

export default MapMarkerSnippetCard;
