import i18n from '../i18n';
import { ITranslation } from '../types/ApiTypes';
import { Langs } from '../types/GlobalTypes';

const useFindTranslation = () => {
  const currentLang = i18n.resolvedLanguage ?? Langs.PL;

  const findTranslation = (translations: ITranslation[]) =>
    translations.find(tr => tr.lang === currentLang)?.value ?? '';

  return { findTranslation };
};

export default useFindTranslation;
