import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { api } from '../../../static/api';
import { getApiErrorMessage } from '../../../utils/api/getApiErrorMessage';
import useAxiosInstance from '../../axios/useAxiosInstance';
import useToast from '../../useToast';

const useAddQuestionImages = () => {
  const axiosInstance = useAxiosInstance();
  const { t } = useTranslation();
  const { showErrorToast } = useToast();

  const uploadImages = async ({
    questionId,
    mainImage,
    correctAnswerImage,
    incorrectAnswerImage,
    markerIcon,
  }: {
    questionId: string;
    mainImage: File | null;
    correctAnswerImage: File | null;
    incorrectAnswerImage: File | null;
    markerIcon?: File | null;
  }) => {
    const formData = new FormData();

    if (mainImage) {
      formData.append('image', mainImage);
    }

    if (correctAnswerImage) {
      formData.append('correctAnswerImage', correctAnswerImage);
    }

    if (incorrectAnswerImage) {
      formData.append('incorrectAnswerImage', incorrectAnswerImage);
    }

    if (markerIcon) {
      formData.append('icon', markerIcon);
    }

    const response = await axiosInstance.post(api.endpoints.QUESTION_IMAGES({ id: questionId }), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const { mutateAsync, isLoading } = useMutation(uploadImages, {
    onError: (err: AxiosError) => {
      const errorMessage = getApiErrorMessage(err) ?? t('errorMessages.addQuestionImageFailed');

      showErrorToast(errorMessage);
    },
  });

  return { addQuestionImages: mutateAsync, isLoading };
};

export default useAddQuestionImages;
