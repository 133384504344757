import { colors } from '../../../styles/variables';
import { mapStyles } from './mapStyles';

export const defaultMapContainerStyle = {
  width: '100%',
  height: '90vh',
  border: `1.5px solid ${colors.grey500}`,
  borderRadius: '6px',
};

export const defaultMapCenter = {
  lat: 51.77345724658496,
  lng: 19.45656957464774,
};

export const defaultMapZoom = 13;

export const defaultMapOptions: google.maps.MapOptions = {
  styles: mapStyles,
  zoomControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  clickableIcons: false,
};
