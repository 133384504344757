import { Menu } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { DropdownStyleProps } from '../../../../types/Dropdown.types';
import FilterDropdownGroup from './components/FilterDropdownGroup';

interface DropdownOption {
  id: string;
  value: string;
}

export interface DropdownGroup {
  id: string;
  label: string;
  options: DropdownOption[];
  onSetNewOption: (option: DropdownOption | null) => void;
  activeOptionIds: string[];
}

export interface FilterDropdownProps extends DropdownStyleProps {
  optionGroups: DropdownGroup[];
  renderButton: ({ open }: { open: boolean }) => React.ReactNode;
  className?: string;
}

export const FilterDropdown = ({ optionGroups, renderButton, className }: FilterDropdownProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={twMerge(
        `relative  border-[1.5px] border-grey500  rounded-md text-sm text-black500 font-medium placeholder:text-grey500 focus:outline-2 focus:outline-blue500`,
        className,
      )}
    >
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className={twMerge(`flex justify-between items-center px-3 py-4`)}>
              {renderButton({ open })}
            </Menu.Button>
            <Menu.Items
              className={twMerge(
                `absolute top-full mt-2 w-full min-w-[150px] left-0 rounded-md border-[1.5px] border-grey500  pt-2 bg-white z-50`,
              )}
            >
              {optionGroups?.length > 0 ? (
                optionGroups.map(optionGroup => (
                  <Menu.Item key={optionGroup.id}>
                    <FilterDropdownGroup optionGroup={optionGroup} />
                  </Menu.Item>
                ))
              ) : (
                <li className={twMerge(`py-2 px-3`)}>{t('errorMessages.noData')}</li>
              )}
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  );
};

export default FilterDropdown;
