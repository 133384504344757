import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../../styles/variables';
import { IQuestion } from '../../../../../types/ApiTypes';
import { Coordinates } from '../../../../../types/GlobalTypes';
import { swapArrayItemsPosition } from '../../../../../utils/general/swapArrayItemsPosition/swapArrayItemsPosition';
import AutocompleteListWrapper from '../../../../common/autocompleteList/AutocompleteListWrapper';
import { PrimaryButton } from '../../../../common/buttons/PrimaryButton';
import ArrowLeft from '../../../../icons/ArrowLeft';
import SelectedQuestion from './components/SelectedQuestion';

interface SelectedQuestionsProps {
  selectedQuestions: IQuestion[];
  setSelectedQuestions: Dispatch<SetStateAction<IQuestion[]>>;
  onRemoveFromGame: (questions: IQuestion[]) => void;
  onUpdateQuestionCoordinates: ({
    newCoordinates,
    questionId,
  }: {
    newCoordinates: Coordinates;
    questionId: number;
  }) => void;
  onUpdateSelectedQuestion: (question: IQuestion) => void;
}

const SelectedQuestions = ({
  selectedQuestions,
  setSelectedQuestions,
  onRemoveFromGame,
  onUpdateQuestionCoordinates,
  onUpdateSelectedQuestion,
}: SelectedQuestionsProps) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [firstSelectedQuestion, setFirstSelectedQuestion] = useState<IQuestion | null>(null);
  const [lastSelectedQuestion, setLastSelectedQuestion] = useState<IQuestion | null>(null);

  const filteredQuestions = selectedQuestions
    .filter(question => {
      return (
        question.name.some(name => name.value.toLowerCase().includes(searchValue.toLowerCase().toLowerCase())) ||
        question?.content?.some(content => content.value.toLowerCase().includes(searchValue.toLowerCase()))
      );
    })
    .sort((a, b) => {
      return b.id - a.id;
    });

  const [selectedToRemoveQuestions, setSelectedToRemoveQuestions] = useState<IQuestion[]>([]);

  const toggleSelectedToRemoveQuestions = (event: MouseEvent, newQuestion: IQuestion) => {
    if (event.shiftKey) {
      if (lastSelectedQuestion) {
        setLastSelectedQuestion(null);
        setFirstSelectedQuestion(null);
        return;
      }
      if (!firstSelectedQuestion) {
        setFirstSelectedQuestion(newQuestion);
      } else {
        const questionsToSelect = selectedQuestions.filter(question => {
          return firstSelectedQuestion.id > newQuestion.id
            ? question.id < firstSelectedQuestion.id && question.id > newQuestion.id
            : question.id > firstSelectedQuestion.id && question.id < newQuestion.id;
        });

        for (const questionBetween of questionsToSelect) {
          toggleSelectedToRemoveQuestions({ ...event, shiftKey: false }, questionBetween);
        }
      }
    }
    setSelectedToRemoveQuestions(prevQuestions => {
      const foundQuestion = prevQuestions.find(question => question.id === newQuestion.id);

      if (foundQuestion) {
        return prevQuestions.filter(question => question.id !== newQuestion.id);
      } else {
        return [...prevQuestions, newQuestion];
      }
    });
  };

  const handleRemoveFromGame = () => {
    onRemoveFromGame(selectedToRemoveQuestions);
    setSelectedToRemoveQuestions([]);
  };

  const moveQuestion = (oldIndex: number, newIndex: number) => {
    setSelectedQuestions(prevQuestions => {
      const newSortedQuestions = [...prevQuestions];
      swapArrayItemsPosition(newSortedQuestions, oldIndex, newIndex);

      return newSortedQuestions;
    });
  };

  const handleMoveUp = ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => {
    e.stopPropagation();
    moveQuestion(currentIndex, currentIndex - 1);
  };

  const handleMoveDown = ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => {
    e.stopPropagation();
    moveQuestion(currentIndex, currentIndex + 1);
  };

  return (
    <>
      <p className="text-black900 text-sm mb-3">{t('templateForm.questions.selectedQuestions')}</p>

      <AutocompleteListWrapper
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedItemsNumber={selectedQuestions?.length}
        buttons={
          <PrimaryButton
            onClick={handleRemoveFromGame}
            className="w-fit"
            disabled={!(selectedToRemoveQuestions?.length > 0)}
            prefixIcon={<ArrowLeft color={colors.white900} />}
          >
            {t('templateForm.questions.removeFromGameBtn')}
          </PrimaryButton>
        }
      >
        <>
          {selectedQuestions.length === 0 ? (
            <div className="w-full h-full flex justify-center items-center">
              <p className="font-medium text-grey500 max-w-xs text-center">
                {t('templateForm.questions.noSelectedQuestionsMessage')}
              </p>
            </div>
          ) : (
            <ul className="p pr-3">
              {filteredQuestions.map((question, index) => {
                const isSelected = selectedToRemoveQuestions.some(
                  selectedQuestion => selectedQuestion.id === question.id,
                );

                const canMoveUp = index !== 0;
                const canMoveDown = index !== selectedQuestions.length - 1;

                return (
                  <SelectedQuestion
                    key={question.id}
                    question={question}
                    index={index}
                    isSelected={isSelected}
                    onTileClick={(event: MouseEvent) => toggleSelectedToRemoveQuestions(event, question)}
                    showDivider
                    canMoveUp={canMoveUp}
                    canMoveDown={canMoveDown}
                    onClickMoveUp={handleMoveUp}
                    onClickMoveDown={handleMoveDown}
                    onUpdateQuestionCoordinates={onUpdateQuestionCoordinates}
                    onUpdateSelectedQuestion={onUpdateSelectedQuestion}
                  />
                );
              })}
            </ul>
          )}
        </>
      </AutocompleteListWrapper>
    </>
  );
};

export default SelectedQuestions;
