import { CircularProgress } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import Switch from '../../../../components/common/switch/Switch';
import Map from '../../../../components/map/Map';
import MapQuestionsLegend from '../../../../components/mapQuestionsLegend/MapQuestionsLegend';
import MapTeamsRanking from '../../../../components/mapTeamsRanking/MapTeamsRanking';
import useGameQuestions from '../../../../hooks/api/games/useGameQuestions';
import useGameUsers from '../../../../hooks/api/games/useGameUsers';
import useSingleGame from '../../../../hooks/api/games/useSingleGame';
import { searchParamsData } from '../../../../static/searchParamsData';
import { GameStatuses } from '../../../../types/GlobalTypes';
import SingleActiveGamePageWrapper from '../../SingleActiveGamePageWrapper';
import SingleActiveGameMapTime from './components/SingleActiveGameMapTime';
import ActiveGameQuestions from './components/questions/ActiveGameQuestions';
import ActiveGameTeams from './components/teams/ActiveGameTeams';

const SingleActiveGameMapView = () => {
  const { t } = useTranslation();
  const params = useParams();
  const gameId = params.id as string;
  const [searchParams] = useSearchParams();
  const initialMapCenter = searchParams.get(searchParamsData.center);
  const initialShowQuestions = searchParams.get(searchParamsData.showQuestions);

  const [mapHeight, setMapHeight] = useState(438);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const { gameUsers: users } = useGameUsers({ gameId });
  const teams = useMemo(() => users?.map(user => user.userGame) ?? [], [users]);

  const { game } = useSingleGame({ gameId });
  const template = game?.template;

  const { gameQuestions, isFetching: isFetchingGameQuestions } = useGameQuestions({ gameId });

  const [isMapDragDisabled, setIsMapDragDisabled] = useState(false);

  const [areQuestionsVisible, setAreQuestionsVisible] = useState(initialShowQuestions === 'true');

  useEffect(() => {
    if (mapContainerRef.current) {
      const distance = window.innerHeight - mapContainerRef.current.offsetTop;
      setMapHeight(distance);
    }
  }, []);

  const mapStyles = { width: '100%', height: `${mapHeight - 48}px` };

  const mapCenter = useMemo(() => {
    if (initialMapCenter) {
      const centerValues = initialMapCenter.split(',');
      const lat = centerValues[0];
      const lng = centerValues[1];

      if (!!lat && !!lng) {
        return {
          lat: +lat,
          lng: +lng,
        };
      }
    }

    const center =
      !!template?.coordinates?.lat && !!template?.coordinates?.lng
        ? {
            lat: template?.coordinates?.lat,
            lng: template?.coordinates?.lng,
          }
        : undefined;

    return center;
  }, [template?.coordinates?.lat, template?.coordinates?.lng, initialMapCenter]);

  return (
    <SingleActiveGamePageWrapper>
      <div ref={mapContainerRef}>
        <Map
          center={mapCenter}
          containerStyle={mapStyles}
          options={{ gestureHandling: isMapDragDisabled ? 'none' : undefined }}
        >
          {!!game && !!template && (
            <>
              {game.status === GameStatuses.ONGOING && (
                <div className="absolute left-2 top-4">
                  <SingleActiveGameMapTime gameId={gameId} startDate={game.startDate} endDate={game.endDate} />
                </div>
              )}
              <div className="absolute right-2 top-2 flex justify-between items-center gap-2.5">
                {isFetchingGameQuestions && <CircularProgress size={25} />}
                <Switch isChecked={areQuestionsVisible} onChange={() => setAreQuestionsVisible(prev => !prev)} />
                <p className="font-bold">{t('singleActiveGame.map.questionsSwitchLabel')}</p>
              </div>
              {areQuestionsVisible && (
                <ActiveGameQuestions
                  templateId={template.id.toString()}
                  gameId={gameId}
                  templateQuestions={gameQuestions}
                  mapHeight={mapHeight}
                  isMapDragDisabled={isMapDragDisabled}
                  setIsMapDragDisabled={setIsMapDragDisabled}
                />
              )}
              <div className="z-10 absolute bottom-3 left-2 flex flex-col gap-1.5">
                <div className="relative">
                  <MapTeamsRanking teams={teams} />
                </div>
                <div className="relative">
                  <MapQuestionsLegend />
                </div>
              </div>
              <ActiveGameTeams teams={teams} />
            </>
          )}
        </Map>
      </div>
    </SingleActiveGamePageWrapper>
  );
};

export default SingleActiveGameMapView;
