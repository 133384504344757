import { Checkbox, FormControlLabel } from '@mui/material';
import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useMapAutocomplete from '../../../../hooks/api/map/useMapAutocomplete';
import useMapPlaceDetails from '../../../../hooks/api/map/useMapPlaceDetails';
import useDebounce from '../../../../hooks/useDebounce';
import { IGooglePlacesPrediction } from '../../../../types/ApiTypes';
import { Coordinates } from '../../../../types/GlobalTypes';
import { ReactStateSetter } from '../../../../types/Types';
import { getCoordinatesFromString } from '../../../../utils/general/getCoordinatesFromString/getCoordinatesFromString';
import { getCoordinatesStringFromLatLng } from '../../../../utils/general/getCoordinatesStringFromLatLng/getCoordinatesStringFromLatLng';
import getInputState from '../../../../utils/getInputState/getInputState';
import MapAutocompleteDropdown from '../../../common/dropdowns/mapAutocomplete/MapAutocompleteDropdown';
import FormSectionTitle from '../../../common/formSectionTitle/FormSectionTitle';
import { LabeledComponent } from '../../../common/inputs/LabeledComponent';
import { LabeledStateInput } from '../../../common/inputs/LabeledStateInput';
import Map from '../../../map/Map';
import MapMarkerBasic from '../../../map/components/MapMarkerBasic';
import { QuestionFormInputs, QuestionFormInputsKeys } from '../types/QuestionFormTypes';

interface QuestionFormLocationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage: (field: QuestionFormInputsKeys) => any;
  isLocation: boolean;
  setIsLocation: ReactStateSetter<boolean>;
  mapCenter?: Coordinates;
  mapZoom?: number;
  initialCity: string;
}

const QuestionFormLocation = ({
  getErrorMessage,
  isLocation,
  setIsLocation,
  mapCenter,
  mapZoom,
  initialCity,
}: QuestionFormLocationProps) => {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext<QuestionFormInputs>();

  const [cityInput, setCityInput] = useState(initialCity);
  const { debouncedValue: debouncedCityInput } = useDebounce({ value: cityInput, delay: 700 });
  const [selectedCityPrediction, setSelectedCityPrediction] = useState<IGooglePlacesPrediction | null>(null);
  const { predictions: cityPredictions } = useMapAutocomplete({ input: debouncedCityInput, onlyCities: true });

  const [locationInput, setLocationInput] = useState('');
  const { debouncedValue: debouncedLocationInput } = useDebounce({ value: locationInput, delay: 700 });
  const [selectedLocationPrediction, setSelectedLocationPrediction] = useState<IGooglePlacesPrediction | null>(null);
  const { predictions: locationPredictions } = useMapAutocomplete({ input: debouncedLocationInput });

  const { placeDetails } = useMapPlaceDetails({
    placeId: selectedLocationPrediction?.placeId ?? '',
    onSuccess: data => {
      const coordinatesString = getCoordinatesStringFromLatLng(data.location);
      setValue('coordinates', coordinatesString);
    },
  });

  const getMapBoundsFromViewport = useCallback(() => {
    if (!placeDetails?.viewport.northeast || !placeDetails?.viewport.southwest) {
      return null;
    }

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(new google.maps.LatLng(placeDetails.viewport.northeast.lat, placeDetails.viewport.northeast.lng));
    bounds.extend(new google.maps.LatLng(placeDetails.viewport.southwest.lat, placeDetails.viewport.southwest.lng));

    return bounds;
  }, [placeDetails?.viewport.northeast, placeDetails?.viewport.southwest]);

  return (
    <>
      <FormSectionTitle className="mb-6" title={t('questionForm.location.title')} />

      <div className="pb-7 max-w-lg">
        <Controller
          name="city"
          control={control}
          render={({ field }) => {
            return (
              <LabeledComponent label={t('questionForm.location.cityInputLabel')}>
                <MapAutocompleteDropdown
                  value={cityInput}
                  setValue={newValue => {
                    setCityInput(newValue);
                  }}
                  predictions={cityPredictions}
                  onSelectPrediction={prediction => {
                    setSelectedCityPrediction(prediction);
                    field.onChange(prediction.structuredFormatting.mainText);
                  }}
                  placeholder={t('questionForm.location.cityInputPlaceholder')}
                  disabled={!isLocation}
                  selectedPrediction={selectedCityPrediction}
                  onlyMainTextOnSetValue
                />
              </LabeledComponent>
            );
          }}
        />
      </div>

      <div className="pb-7 max-w-lg">
        <MapAutocompleteDropdown
          value={locationInput}
          setValue={newValue => {
            setLocationInput(newValue);
          }}
          predictions={locationPredictions}
          onSelectPrediction={prediction => {
            setSelectedLocationPrediction(prediction);
          }}
          placeholder={t('questionForm.location.locationAutocompletePlaceholder')}
          disabled={!isLocation}
          selectedPrediction={selectedLocationPrediction}
        />
      </div>

      <Controller
        name="coordinates"
        control={control}
        render={({ field }) => {
          const coordinates = getCoordinatesFromString(field.value ?? '');

          const currLat = coordinates?.lat;
          const currLng = coordinates?.lng;

          const isCurrent = !!currLat && !!currLng;

          const markerPositions = isCurrent ? [{ lat: currLat, lng: currLng }] : [];

          return (
            <>
              <div className="flex gap-6 justify-between max-w-lg">
                <LabeledStateInput
                  id="coordinates"
                  state={getInputState(getErrorMessage('coordinates'))}
                  labeledProps={{
                    wrapperClassName: 'max-w-xl grow',
                    label: t('questionForm.location.positionLngLabel'),
                    errorMessage: t(`errorMessages.${getErrorMessage('coordinates')?.message}`),
                  }}
                  inputProps={{
                    disabled: !isLocation,
                    placeholder: t('templateForm.general.coordinatesPlacholder'),
                    value: field.value,
                    onChange: field.onChange,
                  }}
                />
              </div>

              <Map
                options={{
                  draggableCursor: isLocation ? 'crosshair' : 'not-allowed',
                }}
                center={placeDetails?.location ?? mapCenter}
                bounds={getMapBoundsFromViewport()}
                initialZoom={mapZoom}
                containerStyle={{
                  width: '100%',
                  maxWidth: '856px',
                  height: '438px',
                  marginTop: '1.5rem',
                }}
                onMapClick={(e: google.maps.MapMouseEvent) => {
                  if (!isLocation) {
                    return;
                  }

                  const lat = e.latLng?.lat();
                  const lng = e.latLng?.lng();

                  if (!!lat && !!lng) {
                    const coordinatesString = getCoordinatesStringFromLatLng({ lat, lng });

                    field.onChange(coordinatesString);
                  }
                }}
              >
                {markerPositions?.map(position => (
                  <MapMarkerBasic key={position.lat} coordinates={position} />
                ))}
              </Map>
              <div className="p-3">
                <FormControlLabel
                  control={<Checkbox checked={!isLocation} onChange={e => setIsLocation(!e.target.checked)} />}
                  label={t('questionForm.location.noPositionCheckboxLabel')}
                />
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default QuestionFormLocation;
