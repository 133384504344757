import { generateURLQueryString } from '../utils/routes/generateURLQueryString';
import { searchParamsData } from './searchParamsData';

interface QuestionsViewFilters {
  search?: string;
  page?: number;
  tag?: string[];
  questionType?: string;
  city?: string;
  owner?: string;
  isArcade?: string;
}

export type QuestionsViewFilter = keyof QuestionsViewFilters;

interface TemplatesViewFilters {
  search?: string;
  page?: number;
  tag?: string[];
  gameCharacter?: string;
  city?: string;
  owner?: string;
}

export type TemplatesViewFilter = keyof TemplatesViewFilters;

export const routes = {
  home: '/',
  dashboard: '/dashboard',
  activeGames: '/active-games',
  activeIndividualGames: '/active-individual-games',
  singleActiveGame: {
    index: (id: string) => `/active-games/${id}`,
    info: (id: string) => `/active-games/${id}/info`,
    map: ({ id, ...params }: { id: string; center?: string; showQuestions?: boolean }) => {
      const queryString = generateURLQueryString(params);

      const url = `/active-games/${id}/map${queryString ? `?${queryString}` : ''}`;
      return url;
    },
    questions: (id: string) => `/active-games/${id}/questions`,
    players: (id: string) => `/active-games/${id}/players`,
    messages: ({ id, messageTo }: { id: string; messageTo?: number }) =>
      `/active-games/${id}/messages${messageTo ? `?${searchParamsData.messageTo}=${messageTo}` : ''}`,
    answers: ({ id, team }: { id: string; team?: string }) =>
      `/active-games/${id}/answers${team ? `?${searchParamsData.team}=${team}` : ''}`,
  },
  singleActiveIndividualGames: {
    index: (id: string) => `/active-individual-games/${id}`,
    info: (id: string) => `/active-individual-games/${id}/info`,
    map: ({ id, ...params }: { id: string; center?: string; showQuestions?: boolean }) => {
      const queryString = generateURLQueryString(params);

      return `/active-individual-games/${id}/map${queryString ? `?${queryString}` : ''}`;
    },
    questions: (id: string) => `/active-individual-games/${id}/questions`,
    players: (id: string) => `/active-individual-games/${id}/players`,
    oneTimeCodes: (id: string) => `/active-individual-games/${id}/codes`,
  },
  historyGames: '/history-games',
  login: '/login',
  requestResetPassword: '/request-reset-password',
  resetPassword: '/reset-password',
  createPassword: '/create-password',
  questions: ({ ...params }: QuestionsViewFilters = {}) => {
    const queryString = generateURLQueryString(params);

    const url = `/questions${queryString ? `?${queryString}` : ''}`;
    return url;
  },
  addQuestion: '/questions/add-question',
  editQuestion: (questionId: string) => `/questions/${questionId}/edit-question`,
  copyQuestion: (questionId: string) => `/questions/${questionId}/copy-question`,
  addRelatedQuestion: ({ parentQuestionId }: { parentQuestionId: string }) =>
    `/questions/${parentQuestionId}/add-related`,
  editRelatedQuestion: ({
    editedQuestionId,
    parentQuestionId,
  }: {
    editedQuestionId: string;
    parentQuestionId: string;
  }) => `/questions/${parentQuestionId}/edit-related/${editedQuestionId}`,
  account: '/account',
  accountPersonalData: '/account/personal-data',
  accountSubscriptions: '/account/subscriptions',
  accountPlayers: '/account/players',
  templates: ({ ...params }: TemplatesViewFilters = {}) => {
    const queryString = generateURLQueryString(params);

    const url = `/templates${queryString ? `?${queryString}` : ''}`;
    return url;
  },
  addTemplate: '/templates/add-template',
  editTemplate: (templateId: string) => `/templates/${templateId}/edit-template`,
  partners: '/partners',
  addPartner: '/partners/add-partner',
  bundles: '/subscription-bundles',
  addBundle: '/subscription-bundles/add-bundle',
  editBundle: (bundleId: number) => `/subscription-bundles/edit-bundle/${bundleId}`,
  copyTemplate: (templateId: string) => `/templates/${templateId}/copy-template`,
  editPartner: (partnerId: number) => `/partners/${partnerId}`,
  allGamers: ({ page }: { page?: number } = {}) => `/all-gamers${!!page ? `?${searchParamsData.page}=${page}` : ''}`,
  gamePreview: ({ gameId, token }: { gameId: string; token: string }) =>
    `/game-preview/${gameId}?${searchParamsData.spectatorToken}=${token}`,
};

export const paths = {
  home: '/',
  dashboard: '/dashboard',
  activeGames: '/active-games',
  singleActiveGame: {
    index: '/active-games/:id',
    info: '/active-games/:id/info',
    map: '/active-games/:id/map',
    questions: '/active-games/:id/questions',
    players: '/active-games/:id/players',
    messages: '/active-games/:id/messages',
    answers: '/active-games/:id/answers',
  },
  activeIndividualGames: '/active-individual-games',
  singleActiveIndividualGames: {
    index: '/active-individual-games/:id',
    info: '/active-individual-games/:id/info',
    map: '/active-individual-games/:id/map',
    questions: '/active-individual-games/:id/questions',
    oneTimeCodes: '/active-individual-games/:id/codes',
    players: '/active-individual-games/:id/players',
  },
  historyGames: '/history-games',
  login: '/login',
  privacyPolicy: '/privacy-policy',
  regulations: '/regulations',
  requestResetPassword: '/request-reset-password',
  resetPassword: '/reset-password',
  createPassword: '/create-password',
  questions: '/questions',
  addQuestion: '/questions/add-question',
  editQuestion: '/questions/:questionId/edit-question',
  copyQuestion: '/questions/:questionId/copy-question',
  addRelatedQuestion: '/questions/:parentQuestionId/add-related',
  editRelatedQuestion: '/questions/:parentQuestionId/edit-related/:editedQuestionId',
  account: '/account',
  accountPersonalData: '/account/personal-data',
  accountSubscriptions: '/account/subscriptions',
  accountPlayers: '/account/players',
  templates: '/templates',
  addTemplate: '/templates/add-template',
  editTemplate: '/templates/:templateId/edit-template',
  partners: '/partners',
  addPartner: '/partners/add-partner',
  bundles: '/subscription-bundles',
  addBundle: '/subscription-bundles/add-bundle',
  editBundle: '/subscription-bundles/edit-bundle/:bundleId',
  copyTemplate: '/templates/:templateId/copy-template',
  editPartner: '/partners/:partnerId',
  allGamers: '/all-gamers',
  gamePreview: '/game-preview/:gameId',
};
