import { useQuery } from 'react-query';
import { api } from '../../../static/api';
import { ApiTranslationsObject } from '../../../types/ApiTypes';
import { QueryAliases } from '../../../types/QueryAliases';
import { ITemplateStats } from '../../../types/TemplateTypes';
import useAxiosInstance from '../../axios/useAxiosInstance';

interface ApiTemplateQuestionStats {
  id: number;
  answersCount: number;
  correctAnswers: number;
  incorrectAnswers: number;
  name: ApiTranslationsObject;
  content: ApiTranslationsObject;
}

interface ApiTemplateStats {
  gamesFromTemplate: number;
  answersCount: number;
  correctAnswers: number;
  incorrectAnswers: number;
  maxPointsToGet: number;
  maxPointsScored: number;
  questionsStatistics: ApiTemplateQuestionStats[];
}

const useTemplateStatistics = ({ id, enabled }: { id: string; enabled: boolean }) => {
  const axiosInstance = useAxiosInstance();

  const { data, refetch, isSuccess, isLoading, isError } = useQuery(
    [QueryAliases.TEMPLATE_STATISTICS(id)],
    () => axiosInstance.get(`${api.endpoints.TEMPLATE_STATISTICS({ templateId: id })}`),
    {
      enabled,
    },
  );

  const apiTemplateStats: ApiTemplateStats | undefined = data?.data;

  const iTemplateStats: ITemplateStats | undefined = !!apiTemplateStats
    ? {
        gamesFromTemplate: apiTemplateStats.gamesFromTemplate,
        answersCount: apiTemplateStats.answersCount,
        correctAnswers: apiTemplateStats.correctAnswers,
        incorrectAnswers: apiTemplateStats.incorrectAnswers,
        maxPointsToGet: apiTemplateStats.maxPointsToGet,
        maxPointsScored: apiTemplateStats.maxPointsScored,
        questionsStatistics: apiTemplateStats.questionsStatistics.map(question => ({
          id: question.id,
          answersCount: question.answersCount,
          correctAnswers: question.correctAnswers,
          incorrectAnswers: question.incorrectAnswers,
          name: question.name.translations,
          content: question.content.translations,
        })),
      }
    : undefined;

  return { templateStatistics: iTemplateStats, refetch, isSuccess, isLoading, isError };
};

export default useTemplateStatistics;
