import { CallbackDefault } from '../../../types/Types';
import XMarkIcon from '../../icons/XmarkIcon';

interface MapTooltipCardProps {
  children: React.ReactNode;
  header: React.ReactNode;
  onCloseClick: CallbackDefault;
  className?: string;
}

const MapTooltipCard = ({ children, header, onCloseClick, className }: MapTooltipCardProps) => {
  return (
    <div className={`pt-3 px-4 pb-6 rounded-lg border border-grey300 bg-white900 ${className ?? ''}`}>
      <div className="flex justify-between gap-4 mb-6">
        {header}
        <button onClick={onCloseClick} className="p-2">
          <XMarkIcon />
        </button>
      </div>

      {children}
    </div>
  );
};

export default MapTooltipCard;
