import { useState } from 'react';
import { defaultMapZoom } from '../static/mapDefaults';

const useMapZoom = ({ initial }: { initial?: number }) => {
  const [zoom, setZoom] = useState(initial ?? defaultMapZoom);

  const handleZoomIn = () => setZoom(z => z + 1);
  const handleZoomOut = () => setZoom(z => z - 1);
  return {
    zoom,
    handleZoomIn,
    handleZoomOut,
  };
};

export default useMapZoom;
