import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import TableCell from '../../../../../components/dataTable/components/TableCell';
import EditIcon from '../../../../../components/icons/EditIcon';
import useQuestionPointsOptions from '../../../../../hooks/api/questions/useQuestionPointsOptions';
import useFindTranslation from '../../../../../hooks/useFindTranslation';
import { routes } from '../../../../../static/routes';
import { colors } from '../../../../../styles/variables';
import { IQuestion } from '../../../../../types/ApiTypes';
import QuestionListTableDeleteBtn from '../components/QuestionListTableDeleteBtn';
import QuestionsListTableTags from '../components/QuestionsListTableTags';

const useQuestionsTableHeaders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { findTranslation } = useFindTranslation();
  const { pointsOptions: allPointsOptions, pointsFallbackColor } = useQuestionPointsOptions();

  const headers = [
    {
      field: 'name',
      headerName: t('questions.table.headers.name'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      valueGetter: (params: GridRenderCellParams<IQuestion>) => findTranslation(params.row.name),
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <TableCell>{findTranslation(params.row.name)}</TableCell>;
      },
    },
    {
      field: 'owner',
      headerName: t('questions.table.headers.owner'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <TableCell>{params.row.owner?.email}</TableCell>;
      },
    },
    {
      field: 'city',
      headerName: t('questions.table.headers.address'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <TableCell>{params.row.city ?? ''}</TableCell>;
      },
    },
    {
      field: 'questionType',
      headerName: t('questions.table.headers.questionType'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <TableCell>{t(`questionTypes.${params.row.questionType.code}`)}</TableCell>;
      },
    },
    {
      field: 'points',
      headerName: t('questions.table.headers.points'),
      minWidth: 64,
      maxWidth: 120,
      valueGetter: (params: GridRenderCellParams<IQuestion>) => params.row.points,
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        const points = params.row.points;
        const color =
          allPointsOptions?.find(option => option.points === points?.toString())?.color ?? pointsFallbackColor;

        const questionPoinstOptions = params.row.pointsOptions;

        return (
          <>
            {!!points && (
              <div
                className="border rounded-full px-2.5 py-2 w-9 h-9 flex justify-center items-center"
                style={{ borderColor: color }}
              >
                <p className="text-sm font-medium" style={{ color }}>
                  {points}
                </p>
              </div>
            )}

            {!!questionPoinstOptions && (
              <div className="px-2.5 py-2 w-9 h-9 flex justify-center items-center ">
                <p className="text-sm font-medium " style={{ color }}>
                  {questionPoinstOptions.map((option, index) => {
                    if (index === questionPoinstOptions.length - 1) {
                      return option;
                    }

                    return `${option}, `;
                  })}
                </p>
              </div>
            )}
          </>
        );
      },
    },

    {
      field: 'tags',
      headerName: t('questions.table.headers.tags'),
      minWidth: 120,
      maxWidth: 240,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <QuestionsListTableTags tags={params.row.tags} />;
      },
    },

    {
      field: 'buttons',
      headerName: '',
      minWidth: 100,
      maxWidth: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return (
          <div className="flex justify-end">
            <HoverableTooltip tooltipContent={<p>{t('questions.table.buttons.edit')}</p>}>
              {isHovered => (
                <button
                  className="p-3"
                  onClick={e => {
                    e.stopPropagation();

                    const currentPath = `${location.pathname}${location.search}`;

                    navigate(routes.editQuestion(params.row.id.toString()), { state: { prevPath: currentPath } });
                  }}
                >
                  <EditIcon color={isHovered ? colors.red500 : colors.grey700} width="24" height="24" />
                </button>
              )}
            </HoverableTooltip>

            <QuestionListTableDeleteBtn questionId={params.row.id.toString()} />
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useQuestionsTableHeaders;
