import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface NavButtonProps {
  href: string;
  text: string;
  icons: {
    default: React.ReactNode;
    active: React.ReactNode;
  };
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

const NavButton = ({ href, text, icons, buttonProps }: NavButtonProps) => {
  const location = useLocation();
  const isActive = () => {
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const hrefSegments = href.split('/').filter(segment => segment !== '');

    return hrefSegments[0] === pathSegments[0];
  };

  const [isHover, setIsHover] = useState(false);

  const icon = isHover || isActive() ? icons.active : icons.default;

  return (
    <Link to={href}>
      <button
        {...buttonProps}
        className={twMerge(
          `relative w-full cursor-pointer flex items-center p-5 rounded-md hover:bg-red700 [&_p]:hover:text-white900 ${
            isActive() && 'bg-red500'
          }`,
        )}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div
          className={`decoration absolute left-1 h-[calc(100%-2px)] my-auto w-1 bg-navy200 rounded ${
            isHover ? 'block' : 'hidden'
          }`}
        />
        <div className="flex items-center justify-start gap-6">
          {icon}
          <p
            className={`text-lg text-start font-bold whitespace-nowrap ${
              isActive() || isHover ? 'text-white900' : 'text-grey700'
            } `}
          >
            {text}
          </p>
        </div>
      </button>
    </Link>
  );
};

export default NavButton;
