import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { Controller, SubmitHandler, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../../../components/common/buttons/PrimaryButton';
import { SecondaryButton } from '../../../../../../components/common/buttons/SecondaryButton';
import { LabeledStateDatepicker } from '../../../../../../components/common/datepicker/LabeledStateDatepicker';
import FormSectionTitle from '../../../../../../components/common/formSectionTitle/FormSectionTitle';
import SingleImagePicker from '../../../../../../components/common/imagePickers/singleImagePicker/SingleImagePicker';
import { LabeledStateInput } from '../../../../../../components/common/inputs/LabeledStateInput';
import { Modal, ModalProps } from '../../../../../../components/common/modals/Modal';
import { LabeledStateTimepicker } from '../../../../../../components/common/timepicker/LabeledStateTimepicker';
import SaveIcon from '../../../../../../components/icons/SaveIcon';
import XMarkIcon from '../../../../../../components/icons/XmarkIcon';
import useAddGamePartnerLogo from '../../../../../../hooks/api/games/useAddGamePartnerLogo';
import useCreateGameInstance, {
  CreateGameInstanceRequest,
  CreateIndividualPaidGameInstanceRequest,
} from '../../../../../../hooks/api/games/useCreateGameInstance';
import useUserDetails from '../../../../../../hooks/api/users/useUserDetails';
import { templateStartGameValidationSchema } from '../../../../../../static/validation/schemas/templateStartGameValidationSchema';
import { IRelatedUser } from '../../../../../../types/ApiTypes';
import getInputState from '../../../../../../utils/getInputState/getInputState';
import TemplatesListTableStartGameModalUsers from './components/TemplatesListTableStartGameModalUsers';
import { getGameDate } from './utils/getGameDate/getGameDate';
import { GameTypes } from '../../../../../../types/GlobalTypes';

interface StartGameModalInputs {
  date: Date;
  startHour: Date;
  endHour: Date;
  numOfPlayers: number;
}

type StartGameModalInputsKeys = keyof StartGameModalInputs;

interface TemplatesListTableStartGameModalProps extends Omit<ModalProps, 'children'> {
  templateId: string;
  gameType: string;
}

const TemplatesListTableStartGameModal = ({
  templateId,
  gameType,
  isOpen,
  setIsOpen,
}: TemplatesListTableStartGameModalProps) => {
  const { t } = useTranslation();

  const [selectedPartnerLogoImg, setSelectedPartnerLogoImg] = useState<File | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<IRelatedUser[]>([]);
  const [allowJoinByCode, setAllowJoinByCode] = useState(false);

  const { user } = useUserDetails();
  const { createGameInstance, isLoading } = useCreateGameInstance({
    onSuccessCb: () => {
      setIsOpen(false);
    },
    gameType,
  });
  const { addGamePartnerLogo } = useAddGamePartnerLogo();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<StartGameModalInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(templateStartGameValidationSchema),
    defaultValues: {
      startHour: undefined,
    },
  });

  const onSubmitBusinessGame: SubmitHandler<StartGameModalInputs> = async data => {
    const startDate = getGameDate({ dateInput: data.date, hourInput: data.startHour });
    const endDate = getGameDate({ dateInput: data.date, hourInput: data.endHour });

    const requestData: CreateGameInstanceRequest = {
      startDate: startDate.toString(),
      endDate: endDate.toString(),
      isAvailableToJoin: allowJoinByCode,
      owner: user?.id as number,
      templateId: +templateId,
      numberOfPlayers: data.numOfPlayers,
      userIds: selectedUsers.map(user => user.id),
    };

    const createGameResponse = await createGameInstance(requestData);
    const createdGameId = createGameResponse.data?.id;

    if (!!createdGameId && !!selectedPartnerLogoImg) {
      addGamePartnerLogo({ gameId: createdGameId, partnerLogo: selectedPartnerLogoImg });
    }
  };

  const onSubmitIndividualPaidGame = async () => {
    const requestData: CreateIndividualPaidGameInstanceRequest = {
      templateId: +templateId,
    };

    const createGameResponse = await createGameInstance(requestData);
    const createdGameId = createGameResponse.data?.id;

    if (!!createdGameId && !!selectedPartnerLogoImg) {
      addGamePartnerLogo({ gameId: createdGameId, partnerLogo: selectedPartnerLogoImg });
    }
  };

  const getErrorMessage = (field: StartGameModalInputsKeys) => get(errors, field);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} contentClassName="w-[50rem] min-w-[50rem]">
      <div>
        <div className="p-6 relative border-b border-grey300">
          <h2 className="text-center text-[1.375rem] font-medium">{t('templates.startGameModal.title')}</h2>
          <div
            onClick={() => setIsOpen(false)}
            className="w-8 h-8 min-w-8 absolute top-6 right-6 lg:left-6 flex justify-center items-center cursor-pointer"
          >
            <XMarkIcon />
          </div>
        </div>

        {gameType === GameTypes.BUSINESS && (
          <form onSubmit={handleSubmit(onSubmitBusinessGame)} className="p-6">
            <FormSectionTitle className="mb-6" title={t('templates.startGameModal.date.title')} />
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <LabeledStateDatepicker
                  id="start-date"
                  state={getInputState(getErrorMessage('date'))}
                  labeledProps={{
                    label: t('templates.startGameModal.date.label'),
                    wrapperClassName: 'w-full max-w-xs',
                    errorMessage: t(`errorMessages.${getErrorMessage('date')?.message}`),
                  }}
                  datepickerProps={{
                    date: field.value,
                    setDate: field.onChange,
                    minDate: new Date(),
                  }}
                />
              )}
            />

            <FormSectionTitle className="mb-6 mt-9" title={t('templates.startGameModal.hour.title')} />

            <div className="flex gap-8">
              <Controller
                name="startHour"
                control={control}
                render={({ field }) => (
                  <LabeledStateTimepicker
                    id="start-hour"
                    state={getInputState(getErrorMessage('startHour'))}
                    labeledProps={{
                      label: t('templates.startGameModal.hour.startHourLabel'),
                      wrapperClassName: 'w-full max-w-xs',
                      errorMessage: t(`errorMessages.${getErrorMessage('startHour')?.message}`),
                    }}
                    timePickerProps={{
                      date: field.value,
                      setDate: field.onChange,
                    }}
                  />
                )}
              />
              <Controller
                name="endHour"
                control={control}
                render={({ field }) => (
                  <LabeledStateTimepicker
                    id="start-hour"
                    state={getInputState(getErrorMessage('endHour'))}
                    labeledProps={{
                      label: t('templates.startGameModal.hour.endHourLabel'),
                      wrapperClassName: 'w-full max-w-xs',
                      errorMessage: t(`errorMessages.${getErrorMessage('endHour')?.message}`),
                    }}
                    timePickerProps={{
                      date: field.value,
                      setDate: field.onChange,
                    }}
                  />
                )}
              />
            </div>

            <div className="mt-9">
              <FormSectionTitle className="mb-6 mt-9" title={t('templates.startGameModal.partnerLogo.title')} />
              <SingleImagePicker
                title={t('templates.startGameModal.partnerLogo.imagePicker.title')}
                subtitle={t('templates.startGameModal.partnerLogo.imagePicker.subtitle')}
                oldImageUrl={''}
                selectedFile={selectedPartnerLogoImg}
                setSelectedFile={setSelectedPartnerLogoImg}
              />
            </div>

            <FormSectionTitle className="my-6" title={t('templates.startGameModal.numOfPlayers.title')} />
            <LabeledStateInput
              id="num-of-players"
              state={getInputState(getErrorMessage('numOfPlayers'))}
              labeledProps={{
                wrapperClassName: 'max-w-xs',
                label: t('templates.startGameModal.numOfPlayers.label'),
                errorMessage: t(`errorMessages.${getErrorMessage('numOfPlayers')?.message}`),
              }}
              inputProps={{
                type: 'number',
                placeholder: t('templates.startGameModal.numOfPlayers.placeholder'),
                register: {
                  ...register('numOfPlayers'),
                },
              }}
            />
            <p className="mt-5 text-black400 text-xs font-normal">
              {t('templates.startGameModal.numOfPlayers.info.standard1')}
              <span className="font-bold">{t('templates.startGameModal.numOfPlayers.info.bold1')}</span>
              {t('templates.startGameModal.numOfPlayers.info.standard2')}
              <span className="font-bold">{t('templates.startGameModal.numOfPlayers.info.bold2')}</span>
            </p>

            <div className="mt-9">
              <FormControlLabel
                control={<Checkbox checked={allowJoinByCode} onChange={e => setAllowJoinByCode(e.target.checked)} />}
                label={t('templates.startGameModal.allowJoinWithCode')}
              />
            </div>

            <TemplatesListTableStartGameModalUsers selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />

            <div className="flex justify-end gap-4">
              <SecondaryButton onClick={() => setIsOpen(false)} className="w-fit">
                {t('templates.startGameModal.cancelBtn')}
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                prefixIcon={<SaveIcon />}
                className="w-fit"
                disabled={isLoading}
                loading={isLoading}
              >
                {t('templates.startGameModal.submitBtn')}
              </PrimaryButton>
            </div>
          </form>
        )}
        {gameType === GameTypes.INDIVIDUAL_PAID && (
          <div className="p-6">
            <div>{t('templates.startGameModal.startIndividualPaid')}</div>
            <div className="flex justify-end gap-4">
              <SecondaryButton onClick={() => setIsOpen(false)} className="w-fit">
                {t('templates.startGameModal.cancelBtn')}
              </SecondaryButton>
              <PrimaryButton
                prefixIcon={<SaveIcon />}
                className="w-fit"
                onClick={() => onSubmitIndividualPaidGame()}
                disabled={isLoading}
                loading={isLoading}
              >
                {t('templates.startGameModal.submitBtn')}
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TemplatesListTableStartGameModal;
