import { GoogleMap, GoogleMapProps, Libraries, useJsApiLoader } from '@react-google-maps/api';
import { CSSProperties, useCallback, useEffect, useRef } from 'react';
import { elementsIds } from '../../static/elementsIds';
import { Coordinates } from '../../types/GlobalTypes';
import MapZoomButtons from './components/MapZoomButton';
import useMapZoom from './hooks/useMapZoom';
import { defaultMapCenter, defaultMapContainerStyle, defaultMapOptions } from './static/mapDefaults';

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

const libraries: Libraries = ['drawing'];

interface MapProps extends GoogleMapProps {
  children: React.ReactNode;
  center?: Coordinates;
  initialZoom?: number;
  options?: google.maps.MapOptions;
  containerStyle?: CSSProperties;
  hideZoomButtons?: boolean;
  onMapClick?: (e: google.maps.MapMouseEvent) => void;
  bounds?: google.maps.LatLngBounds | null;
  onMapLoad?: (map: google.maps.Map) => void;
}

const Map = ({
  children,
  center,
  initialZoom,
  options,
  containerStyle,
  hideZoomButtons = false,
  onMapClick,
  bounds,
  onMapLoad,
}: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    if (mapRef.current && !!bounds) {
      mapRef.current.fitBounds(bounds);
    }
  }, [bounds]);

  const { zoom, handleZoomIn, handleZoomOut } = useMapZoom({ initial: initialZoom });

  const getMapCenterPoint = useCallback(() => {
    return center ?? defaultMapCenter;
  }, [center]);

  return isLoaded ? (
    <div id={elementsIds.map}>
      <GoogleMap
        onLoad={map => {
          mapRef.current = map;

          if (onMapLoad) {
            onMapLoad(map);
          }
        }}
        mapContainerStyle={{ ...defaultMapContainerStyle, ...containerStyle }}
        center={getMapCenterPoint()}
        zoom={zoom}
        options={{ ...defaultMapOptions, ...options }}
        onClick={onMapClick}
      >
        <div className="z-10 absolute bottom-3 right-2 flex flex-col gap-1.5">
          {!hideZoomButtons && <MapZoomButtons onClickPlus={handleZoomIn} onClickMinus={handleZoomOut} />}
        </div>
        {children}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default Map;
