import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import TableCell from '../../../../../components/dataTable/components/TableCell';
import LocationIcon from '../../../../../components/icons/LocationIcon';
import useQuestionPointsOptions from '../../../../../hooks/api/questions/useQuestionPointsOptions';
import useFindTranslation from '../../../../../hooks/useFindTranslation';
import { colors } from '../../../../../styles/variables';
import { IGameQuestion } from '../../../../../types/ApiTypes';
import { Coordinates } from '../../../../../types/GlobalTypes';
import DeleteQuestionBtn from '../components/DeleteQuestionBtn';
import EditQuestionBtn from '../components/EditQuestionBtn';

const useActiveGameQuestionsHeaders = ({
  templateId,
  gameId,
  onLocationClick,
}: {
  templateId: number;
  gameId: number;
  onLocationClick: ({ coordinates }: { coordinates: Coordinates }) => void;
}) => {
  const { t } = useTranslation();
  const { findTranslation } = useFindTranslation();
  const { pointsOptions, pointsFallbackColor } = useQuestionPointsOptions();

  const headers: GridColDef[] = [
    {
      field: 'name',
      headerName: t('singleActiveGame.questions.table.name'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IGameQuestion>) => {
        return <TableCell>{findTranslation(params.row.question.name)}</TableCell>;
      },
    },
    {
      field: 'location',
      headerName: t('singleActiveGame.questions.table.location'),
      minWidth: 180,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IGameQuestion>) => {
        return (
          <div>
            {!!params.row.coordinates ? (
              <>
                <TableCell className="whitespace-nowrap py-0">
                  <span className="text-black400">{t('singleActiveGame.players.table.location.lat')} </span>
                  {params.row.coordinates?.lat},
                </TableCell>

                <TableCell className="whitespace-nowrap py-0">
                  <span className="text-black400">{t('singleActiveGame.players.table.location.lng')} </span>
                  {params.row.coordinates?.lng}
                </TableCell>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      field: 'type',
      headerName: t('singleActiveGame.questions.table.type'),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGameQuestion>) => {
        return <TableCell>{t(`questionTypes.${params.row.question.questionType.code}`)}</TableCell>;
      },
    },
    {
      field: 'points',
      headerName: t('singleActiveGame.questions.table.points'),
      minWidth: 40,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<IGameQuestion>) => {
        const points = params.row.question.points;
        const color = pointsOptions?.find(option => option.points === points?.toString())?.color ?? pointsFallbackColor;

        const questionPoinstOptions = params.row.question.pointsOptions;

        return (
          <>
            {!!points && (
              <div
                className="border rounded-full px-2.5 py-2 w-9 h-9 flex justify-center items-center"
                style={{ borderColor: color }}
              >
                <p className="text-sm font-medium" style={{ color }}>
                  {points}
                </p>
              </div>
            )}

            {!!questionPoinstOptions && (
              <div className="px-2.5 py-2 w-9 h-9 flex justify-center items-center ">
                <p className="text-sm font-medium " style={{ color }}>
                  {questionPoinstOptions.map((option, index) => {
                    if (index === questionPoinstOptions.length - 1) {
                      return option;
                    }

                    return `${option}, `;
                  })}
                </p>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: 'answersNumber',
      headerName: t('singleActiveGame.questions.table.answersNumber'),
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGameQuestion>) => {
        return <TableCell>{`${params.row.answers.current}/${params.row.answers.max}`}</TableCell>;
      },
    },

    {
      field: 'buttons',
      headerName: '',
      maxWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IGameQuestion>) => {
        const coordinates = params.row.coordinates;

        return (
          <div className="flex gap-2 justify-end justify-self-end">
            <EditQuestionBtn question={params.row.question} />

            <HoverableTooltip tooltipContent={<p>{t('singleActiveGame.questions.table.buttons.showLocation')}</p>}>
              {isHovered => (
                <button
                  disabled={!coordinates}
                  className="p-3"
                  onClick={e => {
                    e.preventDefault();

                    if (!!coordinates) {
                      onLocationClick({ coordinates });
                    }
                  }}
                >
                  <LocationIcon color={isHovered ? colors.red500 : colors.grey700} width={24} height={24} />
                </button>
              )}
            </HoverableTooltip>

            <DeleteQuestionBtn questionId={params.row.question.id} templateId={templateId} gameId={gameId} />
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useActiveGameQuestionsHeaders;
