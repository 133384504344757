import { useTranslation } from 'react-i18next';

import { IQuestion } from '../../../../../../types/ApiTypes';
import { Langs } from '../../../../../../types/GlobalTypes';
import { CallbackSomeData } from '../../../../../../types/Types';
import { findValueByLang } from '../../../../../../utils/findValueByLang/findValueByLang';
import AutocompleteListItem from '../../../../../common/autocompleteList/AutocompleteListItem';
import { HorizontalDivider } from '../../../../../common/dividers/HorizontalDivider';

interface SingleQuestionTileProps {
  question: IQuestion;
  isSelected: boolean;
  onClick: CallbackSomeData;
  showDivider?: boolean;
  tileActions?: React.ReactNode;
  children?: React.ReactNode;
  isArcade?: boolean;
}

const SingleQuestionTile = ({
  question,
  isSelected,
  onClick,
  showDivider,
  tileActions,
  children,
  isArcade,
}: SingleQuestionTileProps) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.resolvedLanguage;

  const questionName =
    findValueByLang({ lang: currentLang ?? Langs.PL, array: question.name }) ||
    findValueByLang({ lang: Langs.PL, array: question.name });

  const questionContent =
    findValueByLang({ lang: currentLang ?? Langs.PL, array: question.content!! }) ||
    findValueByLang({ lang: Langs.PL, array: question.content!! });

  const questionText = `${questionName} - ${questionContent}`;

  const trimQuestion = (question: string) => {
    const questionTrimmed = question.length > 50 ? question.substring(0, 50) : question;

    return /[.,;!?\\s]$/.test(questionTrimmed)
      ? questionTrimmed.substring(0, questionTrimmed.length - 1) + '...'
      : questionTrimmed + '...';
  };

  return (
    <>
      <AutocompleteListItem onClick={onClick} isActive={isSelected}>
        <div className="flex items-center justify-between w-full select-none">
          <p>
            {`${t('templateForm.questions.singleQuestionTilePrefix')} ${trimQuestion(questionText)}`}
            {isArcade && <span className="text-red500 font-medium">{t('templateForm.questions.arcadeInfo')}</span>}
          </p>
          {tileActions}
        </div>
      </AutocompleteListItem>
      {children}
      {showDivider && <HorizontalDivider className="my-2" />}
    </>
  );
};

export default SingleQuestionTile;
