import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../styles/variables';
import SearchIcon from '../../icons/SearchIcon';
import XMarkIcon from '../../icons/XmarkIcon';
import IconButton from '../buttons/IconButton';
import { Input } from '../inputs/Input';

interface SearchBarProps {
  value: string;
  setValue: (newValue: string) => void;
}

const SearchBar = ({ value, setValue }: SearchBarProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(prev => !prev);

  const handleClearButtonClick = () => {
    setValue('');
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    containerRef.current?.querySelector('input')?.focus();
  }, [isOpen]);

  return (
    <div ref={containerRef} className="flex h-fit grow">
      <div
        className={`relative flex rounded-md grow ${
          isOpen ? 'outline outline-2 outline-red500 transition-all duration-300' : ''
        }`}
      >
        <div className="relative grow">
          <Input
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder={t('searchBar.placeholder')}
            className={`py-2 px-0 h-full outline-0 focus:outline-0 bg-white900 focus:bg-grey100 ${
              isOpen ? 'w-full transition-all duration-300 pl-3 pr-8' : 'w-0 max-w-0 transition-all duration-300 px-0'
            }`}
          />

          {!!value && isOpen && (
            <button
              onClick={handleClearButtonClick}
              className="px-3 bg-transparent absolute right-0 top-1/2 -translate-y-1/2"
            >
              <XMarkIcon color={colors.red700} />
            </button>
          )}
        </div>

        <IconButton
          onClick={toggleOpen}
          icon={<SearchIcon color={isOpen ? 'white' : 'black'} />}
          className={`${isOpen ? 'bg-red500 bg-opacity-100 rounded-none' : 'border border-grey300 rounded-md'}`}
        />
      </div>
    </div>
  );
};

export default SearchBar;
