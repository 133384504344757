import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, SubmitHandler, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { partnerValidationSchema } from '../../../static/validation/schemas/partnerValidationSchema';
import { InitialPartnerFormValues } from '../../../types/ApiPartnerTypes';
import { DropdownOption } from '../../../types/Dropdown.types';
import getInputState from '../../../utils/getInputState/getInputState';
import BackButton from '../../common/buttons/BackButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { LabeledStateDatepicker } from '../../common/datepicker/LabeledStateDatepicker';
import AutocompleteMultiDropdown from '../../common/dropdowns/AutocompleteMultiDropdown';
import { LabeledStateDropdown } from '../../common/dropdowns/LabeledStateDropdown';
import { LabeledComponent } from '../../common/inputs/LabeledComponent';
import { LabeledStateInput } from '../../common/inputs/LabeledStateInput';
import LoadingOverlay from '../../common/modals/LoadingOverlay';
import useGetPartnerFormFieldStates from './hooks/useGetPartnerFormFieldStates';
import { PartnerFormSubmitProps } from './types/partnerFormTypes';

interface PartnerFormProps {
  submitBtnIcon: React.ReactNode;
  submitBtnText: string;
  initialPartner: InitialPartnerFormValues;
  submitIsInProgess: boolean;
  bundleDropdownOptions: DropdownOption[];
  templatesDropdownOptions: DropdownOption[];
  submitForm: ({
    partner,
    baseDataHasChanged,
    subscriptionHasChanged,
    templatesHasChanged,
  }: PartnerFormSubmitProps) => void;
}

const PartnerForm = ({
  submitForm,
  initialPartner,
  submitBtnText,
  submitBtnIcon,
  submitIsInProgess,
  bundleDropdownOptions,
  templatesDropdownOptions,
}: PartnerFormProps) => {
  const { t } = useTranslation();

  const { register, control, getFieldState, formState, handleSubmit } = useForm<InitialPartnerFormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(partnerValidationSchema),
    defaultValues: initialPartner,
  });

  const { baseDataHasChanged, subscriptionHasChanged, templatesHasChanged } = useGetPartnerFormFieldStates(
    getFieldState,
    formState,
  );

  const [templatesSearchValue, setTemplatesSearchValue] = useState('');

  const [currentBundleOption, setCurrentBundleOption] = useState(initialPartner?.subscription?.bundle);

  const getErrorMessage = (field: keyof InitialPartnerFormValues) => {
    return get(formState.errors, field);
  };

  const onFormSubmit: SubmitHandler<InitialPartnerFormValues> = data => {
    submitForm({
      partner: { ...data },
      baseDataHasChanged,
      subscriptionHasChanged,
      templatesHasChanged,
    });
  };

  return (
    <div className="mb-10">
      <form onSubmit={handleSubmit(onFormSubmit)} className="w-full flex flex-col gap-7 mb-7 relative">
        <div className="flex mb-8 justify-between items-end">
          <BackButton />
          <PrimaryButton
            type="submit"
            className="w-fit self-end"
            prefixIcon={submitBtnIcon}
            disabled={submitIsInProgess}
            loading={submitIsInProgess}
          >
            {submitBtnText}
          </PrimaryButton>
        </div>
        <LabeledStateInput
          id="partner-name"
          state={getInputState(getErrorMessage('baseData')?.name)}
          labeledProps={{
            wrapperClassName: 'max-w-xl',
            label: t('partner.partnerForm.label.name'),
            errorMessage: t(`errorMessages.${getErrorMessage('baseData')?.name?.message}`),
          }}
          inputProps={{
            placeholder: t('partner.partnerForm.placeholder.name'),
            register: {
              ...register(`baseData.name`),
            },
          }}
        />
        <LabeledStateInput
          id="partner-email"
          state={getInputState(getErrorMessage('baseData')?.email)}
          labeledProps={{
            wrapperClassName: 'max-w-xl',
            label: t('partner.partnerForm.label.email'),
            errorMessage: t(`errorMessages.${getErrorMessage('baseData')?.email?.message}`),
          }}
          inputProps={{
            placeholder: t('partner.partnerForm.placeholder.email'),
            register: {
              ...register(`baseData.email`),
            },
          }}
        />
        <LabeledStateInput
          id="partner-phone"
          state={getInputState(getErrorMessage('baseData')?.phone)}
          labeledProps={{
            wrapperClassName: 'max-w-xl',
            label: t('partner.partnerForm.label.phone'),
            errorMessage: t(`errorMessages.${getErrorMessage('baseData')?.phone?.message}`),
          }}
          inputProps={{
            placeholder: t('partner.partnerForm.placeholder.phone'),
            register: {
              ...register(`baseData.phone`),
            },
          }}
        />
        <div className="max-w-xl">
          <Controller
            name="subscription.bundle"
            control={control}
            render={({ field }) => (
              <LabeledStateDropdown
                id="partner-subscription"
                state={getInputState(getErrorMessage('subscription')?.bundle)}
                labeledProps={{
                  label: t('partner.partnerForm.label.bundle'),
                  wrapperClassName: 'w-full',
                  errorMessage: t(`errorMessages.${getErrorMessage('subscription')?.bundle?.value?.message}`),
                }}
                dropdownProps={{
                  placeholder: t('partner.partnerForm.placeholder.bundle'),
                  options: bundleDropdownOptions,
                  currentOption: currentBundleOption,
                  setNewOption: val => {
                    field.onChange(val);
                    setCurrentBundleOption(val);
                  },
                }}
              />
            )}
          />
        </div>
        <LabeledStateInput
          id="partner-coordinates"
          state={getInputState(getErrorMessage('subscription')?.coordinates)}
          labeledProps={{
            wrapperClassName: 'max-w-xl',
            label: t('partner.partnerForm.label.coordinates'),
            errorMessage: t(`errorMessages.${getErrorMessage('subscription')?.coordinates?.message}`),
          }}
          inputProps={{
            placeholder: t('partner.partnerForm.placeholder.coordinates'),
            register: {
              ...register(`subscription.coordinates`),
            },
          }}
        />
        <div className="flex gap-8">
          <Controller
            name="subscription.validUntil"
            control={control}
            render={({ field }) => (
              <LabeledStateDatepicker
                id="start-hour"
                state={getInputState(getErrorMessage('subscription')?.validUntil)}
                labeledProps={{
                  label: t('partner.partnerForm.label.validUntil'),
                  wrapperClassName: 'w-full max-w-xl',
                  errorMessage: t(`errorMessages.required`),
                }}
                datepickerProps={{
                  date: field.value,
                  setDate: field.onChange,
                }}
              />
            )}
          />
        </div>
        <div className="max-w-xl">
          <Controller
            name="templates"
            control={control}
            render={({ field }) => (
              <LabeledComponent
                id="game-type"
                label={t('partner.partnerForm.label.templates')}
                wrapperClassName={'w-full'}
              >
                <AutocompleteMultiDropdown
                  query={templatesSearchValue}
                  setQuery={setTemplatesSearchValue}
                  allOptions={templatesDropdownOptions}
                  setSelectedOptions={field.onChange}
                  selectedOptions={field.value ?? []}
                  placeholder={t('partner.partnerForm.placeholder.templates')}
                />
              </LabeledComponent>
            )}
          />
        </div>
      </form>
      <LoadingOverlay isLoading={submitIsInProgess} />
    </div>
  );
};

export default PartnerForm;
